// 电话上报列表表头
export const columns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '来电日期',
      width: 150,
      align: 'center',
      scopedSlots: { customRender: 'reportTime' }
    },
    {
      title: '姓名',
      width: 80,
      align: 'center',
      dataIndex: 'userName'
    },
    {
      title: '电话',
      width: 110,
      align: 'center',
      dataIndex: 'phone'
    },
    {
      title: '地址',
      align: 'center',
      width: 210,
      dataIndex: 'location'
    },
    {
      title: '来电是由',
      align: 'center',
      dataIndex: 'reportReason'
    },
    {
      title: '处理结果',
      align: 'center',
      dataIndex: 'disposeResult',
      ellipsis: 'true'
    },
    {
      title: '回访结果',
      align: 'center',
      dataIndex: 'callBackResult',
      ellipsis: 'true'
    },
    {
      title: '操作',
      width: 200,
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ]
}

// 回访状态
export const callbackList = function () {
  return [
    { value: 'repeated', label: '已回访' },
    { value: 'unrepeated', label: '未回访' }
  ]
}
// 处理状态
export const handleStatus = function () {
  return [
    { value: 'processed', label: '已处理' },
    { value: 'unprocessed', label: '未处理' }

  ]
}

//状态
export const statusList = function () {
  return [
    { value: 'callback_repeated', type: 'callback', label: '已回访' },
    { value: 'callback_unrepeated', type: 'callback', label: '未回访' },
    { value: 'handle_processed', type: 'handle', label: '已处理' },
    { value: 'handle_unprocessed', type: 'handle', label: '未处理' }
  ]
}


// 处理状态
export const phoneType = function () {
  return [
    {
      key: 'repair',
      tab: '维修电话'
    },
    {
      key: 'advisory',
      tab: '咨询电话'
    },
    {
      key: 'complain',
      tab: '投诉电话'
    }

  ]
}

