<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">电话上报</h1>
      <a-card :bordered="false"
              :headStyle="{padding:'0'}"
              style="width:100%"
              :tab-list="tabListNoTitle"
              :active-tab-key="searchParams.phoneType"
              @tabChange="key => onTabChange(key)"
      >
      </a-card>
    </div>
    <div slot="extra">
      <a-button style="margin-left: 16px;" icon="plus" @click="handleAdd" type="primary">新增</a-button>
    </div>
    <a-card :bordered="false" style="margin-top: 15px;">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="来电日期">
              <a-range-picker style="width: 260px;" v-model="dateSearch"
                              format="YYYY-MM-DD"
                              @change="handleModalPickerOk"></a-range-picker>
            </a-form-item>
            <a-form-item label="电话">
              <a-input v-model="searchParams.phone" placeholder="请输入电话"/>
            </a-form-item>
            <a-form-item label="姓名">
              <a-input v-model="searchParams.name" placeholder="请输入姓名"/>
            </a-form-item>
            <a-form-item label="状态">
              <a-select allowClear
                        optionFilterProp="label"
                        v-model="statusValue"
                        placeholder="请选择状态"
                        @change="onSelectStatus">
                <a-select-option v-for="item in statusList" :key="item.value" :value="item.value" :label="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <!--            <a-form-item label="处理状态">-->
            <!--              <a-select allowClear-->
            <!--                        optionFilterProp="label"-->
            <!--                        v-model="searchParams.disposeType"-->
            <!--                        placeholder="请选择"-->
            <!--                        @change="search">-->
            <!--                <a-select-option v-for="item in handleStatus" :key="item.value" :value="item.value" :label="item.label">-->
            <!--                  {{ item.label }}-->
            <!--                </a-select-option>-->
            <!--              </a-select>-->
            <!--            </a-form-item>-->
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table size="middle"
               :columns="columns"
               :dataSource="tableData"
               :loading="tableLoading"
               :pagination="pagination"
               @change="tableChange"
               :rowKey="(record) => record.id"
               :customRow="handleView">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="reportTime" slot-scope="text, record">
          {{moment(record.reportTime).format('YYYY-MM-DD')}}
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click.stop="handleEdit(record.id)">编辑</a>
          <a-divider type="vertical"/>
          <a @click.stop="handleDispose(record.id)">处理</a>
          <a-divider type="vertical"/>
          <a @click.stop="handleCallBack(record.id)">回访</a>
          <a-divider type="vertical"/>
          <a @click.stop="handleDelete(record.id)">删除</a>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal
      :title="searchParams.phoneType==='repair'?'维修电话上报':(searchParams.phoneType==='advisory'?'咨询电话上报':'投诉电话上报')"
      @ok="handleAddOk"
      @cancel="closeAddModal"
      :visible="addModal"
      :width="600"
      type="add">
      <repair-form ref="addRef" @addSuccess="addSuccess" :phoneType="searchParams.phoneType" type="add" @addError="addError"></repair-form>
    </a-modal>
    <!--修改Modal-->
    <a-modal
      :title="searchParams.phoneType==='repair'?'修改维修电话':(searchParams.phoneType==='advisory'?'修改咨询电话':'修改投诉电话')"
      :visible="editModal"
      @ok="handleEditOk"
      @cancel="closeEditModal"
      :width="600"
      :confirmLoading="editLoading">
      <repair-form ref="editRef" type="edit" :phoneType="searchParams.phoneType" @editSuccess="editSuccess" @editError="editError"></repair-form>
    </a-modal>
    <!--处理Modal-->
    <a-modal
      :visible="disposeModal"
      okText="处理"
      :width="1000"
      @ok="handleDisposeOk"
      @cancel="closeDisposeModal"
      :confirmLoading="disposeLoading"
      :bodyStyle="bodyStyle">
      <DisposeForm ref="disposeRef" @disposeSuccess="disposeSuccess"></DisposeForm>
    </a-modal>
    <!--回访Modal-->
    <a-modal
      :visible="callBackModal"
      :width="1000"
      @ok="handleCallBackOk"
      @cancel="closeCallBackModal"
      :confirmLoading="callBackLoading"
      :bodyStyle="bodyStyle">
      <CallBackForm ref="callBackRef" @callBackSuccess="callBackSuccess"></CallBackForm>
    </a-modal>
    <a-drawer
      title="电话上报详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      :width="400"
      :visible="detailDrawer"
      :mask="false">
      <Detail ref="viewRef"></Detail>
    </a-drawer>
  </page-layout>
</template>

<script>
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import { columns, phoneType, statusList } from './common/common'
  import entityCRUDWithCopy from '../../common/mixins/entityCRUDWithCopy'
  import RepairForm from './Form'
  import moment from 'moment'
  import DisposeForm from './DisposeForm'
  import CallBackForm from './CallbackForm'
  import Detail from './Detail'

  export default {
    name: 'carMaintainList',
    components: { PageLayout, RepairForm, DisposeForm, CallBackForm, Detail },
    mixins: [entityCRUDWithCopy],
    data () {
      return {
        dateSearch: null,
        entityBaseUrl: SERVICE_URLS.csgz.phoneReportApi,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          name: null,
          phone: null,
          disposeType: undefined,
          callBackType: undefined,
          codes: null,
          phoneType: 'repair',
          startTime: null,
          endTime: null
        },
        initColumns: columns(),
        statusList: statusList(),
        tabListNoTitle: phoneType(),
        disposeModal: false,
        disposeLoading: false,
        callBackModal: false,
        callBackLoading: false,
        bodyStyle: {
          background: '#f0f2f5',
          padding: '0'
        },
        statusValue: undefined,
        buttonLoad: true
      }
    },
    created () {
    },
    methods: {
      moment,
      onTabChange (key) {
        this.searchParams.phoneType = key
        this.search()
      },
      handleModalPickerOk (value) {
        if (value.length > 0) {
          this.searchParams.startTime = value[0].format('YYYY-MM-DD')
          this.searchParams.endTime = value[1].format('YYYY-MM-DD')
        } else {
          this.searchParams.startTime = ''
          this.searchParams.endTime = ''
        }
        this.search()
      },
      resetForm () {
        this.statusValue = undefined
        this.dateSearch = null
        this.searchParams.name = null
        this.searchParams.phone = null
        this.searchParams.disposeType = undefined
        this.searchParams.callBackType = undefined
        this.searchParams.startTime = ''
        this.searchParams.endTime = ''
        this.search()
      },
      handleDisposeOk () {
        this.$nextTick(() => {
          this.$refs['disposeRef'].handDispose()
        })
      },
      closeDisposeModal () {
        this.disposeModal = false
      },
      disposeSuccess (data) {
        this.disposeModal = false
        this.$message.success('处理成功')
        this.search()
      },
      handleDispose (id) {
        this.disposeModal = true
        this.$nextTick(() => {
          this.$refs['disposeRef'].loadData(id)
        })
      },
      handleCallBackOk () {
        this.callBackModal = false
        this.$message.success('回访完成')
        this.$nextTick(() => {
          this.$refs['callBackRef'].handCallBack()
        })
      },
      closeCallBackModal () {
        this.callBackModal = false
      },
      handleCallBack (id) {
        this.callBackModal = true
        this.$nextTick(() => {
          this.$refs['callBackRef'].loadData(id)
        })
      },
      callBackSuccess () {
        this.callBackModal = false
        this.search()
      },
      handleView (record) {
        return {
          on: {
            click: () => {
              this.detailDrawer = true
              this.$nextTick(() => {
                this.$refs.viewRef.loadData(record.id)
              })
            }
          }
        }
      },
      onSelectStatus (value) {
        this.statusValue = value
        if (value === undefined) {
          this.searchParams.disposeType = undefined
          this.searchParams.callBackType = undefined
        } else {
          const stringArray = value.split('_')
          if (stringArray[0] === 'callback') {
            this.searchParams.disposeType = undefined
            this.searchParams.callBackType = stringArray[1]
          } else {
            this.searchParams.callBackType = undefined
            this.searchParams.disposeType = stringArray[1]
          }
        }

        this.search()
      }
    }
  }
</script>

<style scoped></style>
