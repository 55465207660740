export const phoneReportVO = function () {
  return {
    spotId: '',
    spotName: '',
    reportTime: '',
    areaId: '',
    location: '',
    userName: '',
    phone: '',
    reportReason: '',
    imageIds: [],
    images: [],
    phoneType: ''
  }
}

export const disposeVO = function () {
  return {
    id: '',
    time: '',
    name: '',
    reason: '',
    imageIds: [],
    images: [],
    type: 'dispose'
  }
}
export const callBackVO = function () {
  return {
    id: '',
    time: '',
    name: '',
    reason: '',
    imageIds: [],
    images: [],
    type: 'callback'
  }
}