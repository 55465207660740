<template>
  <a-spin :spinning="spinShow">
    <div class="details">
      <div class="details-title">
        <div class="details-title-text">来电事由</div>
        <i class="details-title-text-i"></i>
      </div>
      <a-descriptions :column="1" >
        <a-descriptions-item label="日期"> {{phoneReportInfo.reportTime?moment(phoneReportInfo.reportTime).format('YYYY-MM-DD') : ''}}</a-descriptions-item>
        <a-descriptions-item label="姓名">{{phoneReportInfo.userName}}</a-descriptions-item>
        <a-descriptions-item label="电话">{{phoneReportInfo.phone}}</a-descriptions-item>
        <a-descriptions-item label="地址">{{phoneReportInfo.location}}</a-descriptions-item>
        <a-descriptions-item label="来电事由">{{phoneReportInfo.reportReason}}</a-descriptions-item>
        <a-descriptions-item label="图片信息" v-if="phoneReportInfo.images.length>0">
          <qiniu-image-upload
            ref="qiniuImageRef"
            :multiSelection="true"
            type="view"
            :items="phoneReportInfo.images"
          ></qiniu-image-upload>
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <div class="details" style="margin-top: 10px">
      <div class="details-title">
        <div class="details-title-text">维修方法</div>
        <i class="details-title-text-i"></i>
      </div>
      <a-descriptions :column="1">
        <a-descriptions-item label="日期"> {{phoneReportInfo.disposeTime?moment(phoneReportInfo.disposeTime).format('YYYY-MM-DD'): ''}}</a-descriptions-item>
        <a-descriptions-item label="姓名">{{phoneReportInfo.disposeName}}</a-descriptions-item>
        <a-descriptions-item label="处理方法">{{phoneReportInfo.disposeResult}}</a-descriptions-item>
        <a-descriptions-item label="图片信息" v-if="phoneReportInfo.disposeImages.length>0">
          <qiniu-image-upload
            ref="qiniuImageRef"
            :multiSelection="true"
            type="view"
            :items="phoneReportInfo.disposeImages"
          ></qiniu-image-upload>
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <div class="details" style="margin-top: 10px">
      <div class="details-title">
        <div class="details-title-text">回访</div>
        <i class="details-title-text-i"></i>
      </div>
      <a-descriptions :column="1">
        <a-descriptions-item label="日期"> {{phoneReportInfo.callBackTime?moment(phoneReportInfo.callBackTime).format('YYYY-MM-DD') : ''}}</a-descriptions-item>
        <a-descriptions-item label="姓名">{{phoneReportInfo.callBackName}}</a-descriptions-item>
        <a-descriptions-item label="回访结果">{{phoneReportInfo.callBackResult}}</a-descriptions-item>
        <a-descriptions-item label="图片信息" v-if="phoneReportInfo.callBackImage.length>0">
          <qiniu-image-upload
            ref="qiniuImageRef"
            :multiSelection="true"
            type="view"
            :items="phoneReportInfo.callBackImage"
          ></qiniu-image-upload>
        </a-descriptions-item>
      </a-descriptions>
    </div>
  </a-spin>
</template>

<script>
  import { callBackVO } from './common/FormVO'
  import SERVICE_URLS from '@/api/service.url'
  import moment from 'moment'
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'

  export default {
    name: 'CheckedForm',
    mixins: [],
    props: {},
    components: { QiniuImageUpload },
    mounted () {
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.csgz.repairRecordApi,
        formItem: callBackVO(),
        showFormItem: false,
        spinShow: false,
        phoneReportInfo: {
          reportTime: null,
          disposeTime: null,
          images: [],
          disposeImages: [],
          callBackImage: []
        }
      }
    },
    computed: {},
    created () {
    },
    methods: {
      moment,
      loadData (id) {
        this.formItem.id = id
        this.$http(this, {
          url: SERVICE_URLS.csgz.phoneReportApi.view,
          params: { id },
          noTips: true,
          success: (data) => {
            this.phoneReportInfo = data.body.phoneReportInfo
            this.phoneReportInfo.images = data.body.phone_report_image
            this.phoneReportInfo.disposeImages = data.body.phone_report_processed_image
            this.phoneReportInfo.callBackImage = data.body.phone_report_repeated_image
          }
        })
      }
    }
  }
</script>
