<template>
  <a-spin :spinning="spinShow">
    <a-card :headStyle="{fontWeight:'bold'}" title="来电事由" :bordered="false">
      <a-form-model :model="phoneReportInfo">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-model-item label="日期" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-date-picker class="custom-disabled" :disabled="true" style="width: 100%"
                             v-model="phoneReportInfo.reportTime" format="YYYY-MM-DD"
                             placeholder="请选择"></a-date-picker>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="姓名" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input class="custom-disabled" :disabled="true" v-model="phoneReportInfo.userName"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="电话" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input class="custom-disabled" :disabled="true" v-model="phoneReportInfo.phone"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="地址" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input class="custom-disabled" :disabled="true" v-model="phoneReportInfo.location"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="来电事由" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-textarea class="custom-disabled" :disabled="true" v-model="phoneReportInfo.reportReason" rows="1"></a-textarea>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="phoneReportInfo.images.length>0">
          <a-col :span="8">
            <a-form-model-item class="display-image-edit" label="图片" :label-col="labelCol" :wrapper-col="wrapperCol">
              <qiniu-image-upload
                ref="qiniuImageRef"
                :multiSelection="true"
                type="edit"
                :items="phoneReportInfo.images"
              ></qiniu-image-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>
    <a-card title="处理方法" :bordered="false" :headStyle="{fontWeight:'bold'}" style="margin-top: 10px">
      <a-form :form="form">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="维修日期" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-date-picker style="width: 100%"
                             format="YYYY-MM-DD"
                             v-decorator="['time',{initialValue: moment(),rules: [{ required: true, message: '上报时间不能为空' }]}]"
                             placeholder="请选择"></a-date-picker>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="维修人" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input v-decorator="['name' ]" placeholder="请输入姓名"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="维修方法" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-textarea v-decorator="['reason', { rules: [{ required: true, message: '上报原因不能为空' }] }]" rows="1" :maxLength="200" placeholder="请输入上报原因，最多输入200字"></a-textarea>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item label="图片" :label-col="{span:'2'}" :wrapper-col="wrapperCol" style="    margin-left: -2px;">
              <qiniu-image-upload
                ref="qiniuImageRef"
                @getImageData="getImageData"
                :multiSelection="true"
                type="edit"
                :items="formItem.images"
              ></qiniu-image-upload>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
  </a-spin>
</template>

<script>
  import { disposeVO } from './common/FormVO'
  import SERVICE_URLS from '@/api/service.url'
  import moment from 'moment'
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'
  // import ImageViewItem from '@/components/QiniuUpload/image-view-item.vue'

  export default {
    name: 'CheckedForm',
    mixins: [],
    props: {},
    components: { QiniuImageUpload },
    mounted () {
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.csgz.repairRecordApi,
        form: this.$form.createForm(this),
        formItem: disposeVO(),
        showFormItem: false,
        spinShow: false,
        labelCol: { span: 6 },
        wrapperCol: { span: 17 },
        phoneReportInfo: {
          reportTime: null,
          images: []
        }
      }
    },
    computed: {},
    created () {
    },
    methods: {
      moment,
      loadData (id) {
        this.formItem.id = id
        this.$http(this, {
          url: SERVICE_URLS.csgz.phoneReportApi.view,
          params: { id },
          noTips: true,
          success: (data) => {
            this.phoneReportInfo = data.body.phoneReportInfo
            this.phoneReportInfo.reportTime = moment(this.phoneReportInfo.reportTime)
            this.setFields(data.body)
          }
        })
      },
      setFields (values) {
        this.phoneReportInfo.images = values.phone_report_image
        this.formItem.images = values.phone_report_processed_image
        this.form.setFieldsValue({
          time: this.phoneReportInfo.disposeTime ? moment(this.phoneReportInfo.disposeTime) : moment(),
          name: this.phoneReportInfo.disposeName,
          reason: this.phoneReportInfo.disposeResult
        })
      },
      handDispose () {
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.csgz.phoneReportApi.phone_report_dispose_and_callback,
                data: this.formItem,
                params: {
                  id: this.formItem.id
                },
                noTips: true,
                success: (data) => {
                  //清空表单数据
                  this.formItem = disposeVO()
                  this.form.resetFields()
                  this.$emit('disposeSuccess', data.body)
                }
              })
            }
          }
        )
      },
      setVOFields (values) {
        Object.assign(this.formItem, values)
      },
      /*上传图片回调方法*/
      getImageData (data) {
        this.formItem.imageIds = []
        data.forEach(item => {
          this.formItem.imageIds.push(item.id)
        })
      }
    }
  }
</script>
<style lang="less">
  .custom-disabled {
    .ant-input[disabled] {
      color: rgba(0, 0, 0, 0.6);
      background-color: #ffffff;
    }
  }

  .custom-disabled[disabled] {
    color: rgba(0, 0, 0, 0.6);
    background-color: #ffffff;
  }

  .display-image-edit {
    .uploader-box {
      display: none;
    }

    .image-page {
      .file-item {
        .delete-icon {
          display: none;
        }
      }
    }
  }


</style>
